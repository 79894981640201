import { PublishedBook } from '../models/publishedBook'

const selfPublishedBooks: PublishedBook[] = [
  {
    id: 'quarkus',
    title: 'Quarkus云原生微服务开发实战',
    subTitle: '在云原生时代拥抱原生 Java',
    description: '本书以一个完整的实战应用的开发过程作为主线，介绍如何以 Quarkus 为框架来开发微服务架构的云原生应用。本书介绍了微服务和云原生开发中的各个方面，从微服务的开发和测试，到在 Kubernetes 上部署运行，还包括应用的可观察性、安全和服务调用的健壮性等非功能性需求。通过本书的介绍，你可以了解一个真实的云原生应用的开发过程，并学会如何从头开始开发自己的应用。',
    buyUrl: "https://item.jd.com/13438524.html",
    buyText: "去京东购买"
  },
  {
    id: 'java7',
    title: '深入理解 Java 7',
    subTitle: '核心技术与最佳实践',
    description: '介绍 Java 的核心技术以及 Java 7 的新特性',
    buyUrl: "https://weread.qq.com/web/bookDetail/62f32f0059338062fa53964",
    buyText: "去微信读书阅读"
  },
  {
    id: 'java9',
    title: 'Exploring Java 9',
    subTitle: 'Build Modularized Applications in Java',
    description: '介绍 Java 9 的新特性，尤其是 Java 平台模块系统'
  },
]

export default selfPublishedBooks
