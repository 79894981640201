import React from 'react'
import { SelfPublishedBook } from '../models/selfPublishedBook'
import Section from './section'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from 'react-external-link'
import { PublishedBook } from '../models/publishedBook'

const PublishedBookView: React.FC<{
  book: PublishedBook
  transparent: boolean
}> = ({ book, transparent }) => (
  <Section transparent={transparent}>
    <div className="row">
      <div className="col-md-5 center">
        <Img fixed={book.childImageSharp.fixed} />
      </div>

      <div className="col-md-7 text-center text-md-left">
        <div className="heading-block topmargin-sm">
          <h2>{book.title}</h2>
          <span>{book.subTitle}</span>
        </div>

        <p>{book.description}</p>
        {
          book.buyUrl && book.buyText && <ExternalLink href={book.buyUrl}>
          <button className="mt-4 button button-3d button-large button-rounded">
            {book.buyText}
          </button>
        </ExternalLink>
        }
      </div>
    </div>
  </Section>
)

export default PublishedBookView
